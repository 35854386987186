import React, { useState, useEffect, useContext } from "react"
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Paper, TextField, Button, Box, Typography, Switch, Stack, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useForm } from "react-hook-form";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import axios from "axios";
import draftToHtml from 'draftjs-to-html';
import LanguageContext from "../../../../helpers/context"
import Message from "../Message";
import './posts.css'
import translate from "../../../../helpers/translate";
import Errorpage from "../errorPage";

export default function AddPost({ close, selected, state }) {

    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [fileuploaded, setFile] = useState()

    const [reqFile, setReqfile] = useState()
    const [message, setMessage] = useState()
    const [status, setStatus] = useState("success")
    const [pages, setPages] = useState()
    const { language } = useContext(LanguageContext)
    const [settings, setSettings] = useState({ languagemain: "LV", enablemulti: 0 })
    const { register, handleSubmit } = useForm({ defaultValues: null });
    const [multilang, setMultilang] = useState("LV")
    const [title, setTitle] = useState("")
    const [gallerys, setGallerys] = useState([])

    useEffect(() => {
        getPages()
        if (selected.length === 1 && state === true) {
            setEditorState(EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(selected[0]?.preText)
                )
            ))
        };
        setTitle(selected[0]?.title)
        getsettings()
        getgallerry()
    }, []);


    const getsettings = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/getsettings`, { withCredentials: true })
            .then((response) => {
                if (response.data !== "") {
                    setSettings(response.data)
                }
                if(response.data.enablemulti === 0){
                    setMultilang(response.data.languagemain)
                }
            })
            .catch((error) => {
                setMessage(true)
                setStatus("error")
                setMessage(error)
            })
    }

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
    };

    const getPages = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/pages`, { withCredentials: true })
            .then(response => setPages(response.data))
            .catch((error) => {
                setStatus("error")
                setMessage(error)
            })
    }

    const save = (data) => {
        let text = { blocks: [{ text: "" }] }
        let preText = ""

        if (editorState !== "") {
            text = convertToRaw(editorState.getCurrentContent())
            preText = draftToHtml(convertToRaw(editorState.getCurrentContent()))

        }

        const formData = new FormData();
        formData.append("postimg", !reqFile ? selected[0]?.file : reqFile)
        formData.append("text", text?.blocks[0]?.text)
        formData.append("pretext", preText)
        formData.append("state", state)
        formData.append("id", selected.length > 0 ? selected[0]?.id : "")
        formData.append("Title", title)
        formData.append("Published", data.Published)
        formData.append("externalLink", data.externalLink)
        { data.page !== "" && formData.append("page", data.page) }
        { settings.enablemulti === 1 ? formData.append("language", multilang) : formData.append("language", settings.languagemain) }
        { data.gallery !== -1 && formData.append("gallery", data.gallery) }
        let route = 'createPost'
        if (selected.length > 0) {
            route = "updatePost"
        }

        axios.post(`${process.env.REACT_APP_API_URL}/${route}`, formData, {
            withCredentials: true, headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .then((response) => {
                if (response.data.status === "success") {
                    close(response.data.status, response.data.message)
                } else {
                    setMessage(response.data.message)
                    setStatus("error")
                }
            })
            .catch((error) => {
                setMessage(error.message)
                setStatus("error")
            });

    }

    const closing = () => {
        setMessage(false)
    }

    const handleChange = (e) => {
        let img = e.target.files[0];
        setFile(URL.createObjectURL(img))
        setReqfile(img)
    }

    const languagechange = (e) => {
        setMultilang(e.target.value)
        geteditdata(e.target.value)
    }

    const getgallerry = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/galleriesget`, { withCredentials: true })
            .then(response => setGallerys(response.data))
            .catch((error) => {
                setStatus("error")
                setMessage(error)
            })
    }

    const geteditdata = (lang) => {
        axios.get(`${process.env.REACT_APP_API_URL}/multilangposts`, { params: { selected: selected[0].id, lang } }, { withCredentials: true })
            .then(response => {
                setTitle(response.data[0]?.title ? response.data[0]?.title : lang === settings.languagemain ? selected[0].title : "")
                setEditorState(response.data[0]?.pretext ? EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                        convertFromHTML(response.data[0]?.pretext)
                    )) : lang === settings.languagemain ? EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                            convertFromHTML(selected[0]?.preText))) : ""
                )
            }
            )

    }

    const titleset = (e) => {
        setTitle(e.target.value)
    }

    if (!pages) {
        return <Errorpage />
    }

    let set = [...new Set(settings?.languagelist)]
    if (selected[0]?.page === null) {
        selected[0].page = ""
    }

    return (
        <>
            <Message open={message ? true : false} message={message} status={status} func={closing} />

            <form onSubmit={handleSubmit(save)}>
                <div className="flexBox">
                    <div>
                        <div style={{ marginBottom: "20px" }} className="toolbar">
                            <span>
                                <Button variant="outlined"
                                    onClick={close}
                                    sx={{ marginRight: "20px" }}
                                ><ArrowBackIcon />{translate(language, "button-back")}</Button>
                                <Button
                                    variant="contained"
                                    sx={{ marginRight: "20px" }}
                                    color="primary"
                                    type="submit"
                                >
                                    {translate(language, "button-save")}
                                </Button>
                            </span>
                        </div>

                        <Box className="post" >

                            <TextField
                                required
                                id="outlined-basic"
                                size="small"
                                label={translate(language, "header-title")}
                                variant="outlined"
                                name="Title"
                                fullWidth
                                defaultValue={state ? title : ""}
                                onChange={titleset}
                            />

                        </Box>
                        <Paper
                            sx={{ padding: "10px", minHeight: "400px" }}>
                            <Editor
                                editorState={editorState}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={onEditorStateChange}
                                toolbar={{
                                    image: {
                                        className: "noshow",
                                    }
                                }}
                            />
                        </Paper>
                    </div>

                    <div>
                        <Box className="postFile" >
                            <FormControl>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography>{translate(language, "tag-draft")}</Typography>
                                    <Switch defaultChecked={selected[0]?.statuss === 1 & state === true ? true : false} disabled={settings.languagemain === multilang ? false : true} {...register("Published")} />
                                    <Typography>{translate(language, "tag-published")}</Typography>
                                </Stack>
                            </FormControl>
                        </Box>

                        <Box className="postFile" >
                            <FormControl
                            >
                                <InputLabel htmlFor="page" size="small">{translate(language, "input-page")}</InputLabel>
                                <Select
                                    id="page"
                                    label={translate(language, "input-page")}
                                    size="small"
                                    defaultValue={state ? selected[0]?.page : ""}
                                    disabled={settings.languagemain === multilang ? false : true}
                                    {...register("page")}
                                >

                                    {pages.map(x =>
                                        <MenuItem key={x.id} value={x.id} >{x.Name}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Box>

                        <Box className="postFile" >
                            <FormControl>
                                <TextField
                                    id="link"
                                    label={translate(language, "external-link")}
                                    size="small"
                                    defaultValue={state ? selected[0]?.externalLink : ""}
                                    disabled={settings.languagemain === multilang ? false : true}
                                    {...register("externalLink")}
                                />
                            </FormControl>
                        </Box>

                        {((settings.enablemulti === 1 || settings === undefined) && selected.length > 0) && <Box className="postFile" >
                            <FormControl
                                required
                            >
                                <InputLabel htmlFor="language" size="small">{translate(language, "input-language")}</InputLabel>
                                <Select
                                    id="language"
                                    label={translate(language, "input-language")}
                                    size="small"
                                    onChange={languagechange}
                                    defaultValue={settings.languagemain ? settings.languagemain : "LV"}

                                >
                                    {set?.map(x =>
                                        <MenuItem key={x} value={x} >{translate(language, x)}</MenuItem>
                                    )}
                                    {!set.includes(settings.languagemain) && <MenuItem key={settings.languagemain} value={settings.languagemain}>{translate(language, settings.languagemain)} </MenuItem>}
                                </Select>
                            </FormControl>
                        </Box>}

                        <Box className="postFile" >
                            <FormControl
                            >
                                <InputLabel htmlFor="gallery" size="small">{translate(language, "input-gallery")}</InputLabel>
                                <Select
                                    id="gallery"
                                    label={translate(language, "input-gallery")}
                                    size="small"
                                    defaultValue={selected[0]?.gallery ? selected[0]?.gallery : ""}
                                    {...register("gallery")}
                                >
                                    <MenuItem key={-1} value={-1} >{translate(language, "none-gall")}</MenuItem>
                                    {gallerys.map(x =>
                                        <MenuItem key={x.id} value={x.id} >{x.title}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Box>

                        <Box className="postFile" >
                            <Typography>{translate(language, "upload-image")}</Typography>

                            <Box className="boxImg">
                                <img src={selected[0]?.file.trim() !== "" && state === true && !fileuploaded ? process.env.REACT_APP_API_URL + selected[0]?.file : fileuploaded} width="150" height="200" style={{ objectFit: "scale-down", width: "100%" }} />
                            </Box>

                            <Button variant="contained" component="label" sx={{ marginTop: "20px" }} color="primary" disabled={settings.languagemain === multilang ? false : true}>
                                <FileUploadIcon fontSize="small" />
                                {translate(language, "upload")}
                                <input name="postimg" type="file" hidden accept="image/png, image/jpeg" onChange={handleChange} />
                            </Button>
                        </Box>
                    </div>
                </div>
            </form>


        </>
    )
}